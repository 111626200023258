<template>
  <div class="carousel-box">
    <ClientOnly>
      <VueSlickCarousel v-bind="settings">
        <div
          v-for="(product, index) in productData"
          :index="index"
          :key="index"
        >
          <b-card-body class="p-0">
            <ProductCard :product="product" />
          </b-card-body>
        </div>
      </VueSlickCarousel>
    </ClientOnly>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import ProductCard from "@/esf_utrecht_bankxl/core/components/products/category-product/ProductCard";
import heartIcon from "@/esf_utrecht_bankxl/assets/images/heart11.png";
import $ from "jquery";
import ClientOnly from "vue-client-only";

export default {
  name: "CarouselComponent",
  props: ["productData", "settings"],
  components: { VueSlickCarousel, ProductCard, ClientOnly },
  data() {
    return {
      heartIcon,
    };
  },
  mounted() {
    //Product Sliders Button show hide functionality
    $(".slick-prev").css("display", "none");
    $(".slick-next").on("click", function () {
      $(this).parent().find(".slick-prev").css("display", "block");
      //$(".slick-prev").css("display", "block");
    });
  },
};
</script>
