import sofaLogo from "@/esf_utrecht_bankxl/assets/images/sofa.png";
import sofa1Logo from "@/esf_utrecht_bankxl/assets/images/sofa1.png";
import offerLogo from "@/esf_utrecht_bankxl/assets/images/offer.png";

const SELECTED_PRODUCT = [
  {
    productImg: sofaLogo,
    productPrice: 2.2,
    productOfferPrice: 1.129,
    inStockText: "200 stuks",
    productTag: "+/- Werkdagen levertijd",
    offerImage: offerLogo,
    productText:
      "Lorem ipsum dolor sit consectetuer adipiscing sed diam nonummy nibhini",
  },
  {
    productImg: sofaLogo,
    productPrice: 2.2,
    productOfferPrice: 1.129,
    inStockText: "200 stuks",
    productTag: "+/- Werkdagen levertijd",
    offerImage: offerLogo,
    productText:
      "Lorem ipsum dolor sit consectetuer adipiscing sed diam nonummy nibhini",
  },
  {
    productImg: sofaLogo,
    productPrice: 2.2,
    productOfferPrice: 1.129,
    inStockText: "200 stuks",
    productTag: "+/- Werkdagen levertijd",
    offerImage: offerLogo,
    productText:
      "Lorem ipsum dolor sit consectetuer adipiscing sed diam nonummy nibhini",
  },
  {
    productImg: sofaLogo,
    productPrice: 2.2,
    productOfferPrice: 1.129,
    inStockText: "200 stuks",
    productTag: "+/- Werkdagen levertijd",
    offerImage: offerLogo,
    productText:
      "Lorem ipsum dolor sit consectetuer adipiscing sed diam nonummy nibhini",
  },
  {
    productImg: sofaLogo,
    productPrice: 2.2,
    productOfferPrice: 1.129,
    inStockText: "200 stuks",
    productTag: "+/- Werkdagen levertijd",
    offerImage: offerLogo,
    productText:
      "Lorem ipsum dolor sit consectetuer adipiscing sed diam nonummy nibhini",
  },
  {
    productImg: sofaLogo,
    productPrice: 2.2,
    productOfferPrice: 1.129,
    inStockText: "200 stuks",
    productTag: "+/- Werkdagen levertijd",
    offerImage: offerLogo,
    productText:
      "Lorem ipsum dolor sit consectetuer adipiscing sed diam nonummy nibhini",
  },
];

export const BESTSELLER_PRODUCT = [
  {
    productImg: sofa1Logo,
    productPrice: 2.2,
    productOfferPrice: 1.129,
    inStockText: "200 stuks",
    productTag: "+/- Werkdagen levertijd",
    offerImage: offerLogo,
    productText:
      "Lorem ipsum dolor sit consectetuer adipiscing sed diam nonummy nibhini",
  },
  {
    productImg: sofa1Logo,
    productPrice: 2.2,
    productOfferPrice: 1.129,
    inStockText: "200 stuks",
    productTag: "+/- Werkdagen levertijd",
    offerImage: offerLogo,
    productText:
      "Lorem ipsum dolor sit consectetuer adipiscing sed diam nonummy nibhini",
  },
  {
    productImg: sofa1Logo,
    productPrice: 2.2,
    productOfferPrice: 1.129,
    inStockText: "200 stuks",
    productTag: "+/- Werkdagen levertijd",
    offerImage: offerLogo,
    productText:
      "Lorem ipsum dolor sit consectetuer adipiscing sed diam nonummy nibhini",
  },
  {
    productImg: sofa1Logo,
    productPrice: 2.2,
    productOfferPrice: 1.129,
    inStockText: "200 stuks",
    productTag: "+/- Werkdagen levertijd",
    offerImage: offerLogo,
    productText:
      "Lorem ipsum dolor sit consectetuer adipiscing sed diam nonummy nibhini",
  },
  {
    productImg: sofa1Logo,
    productPrice: 2.2,
    productOfferPrice: 1.129,
    inStockText: "200 stuks",
    productTag: "+/- Werkdagen levertijd",
    offerImage: offerLogo,
    productText:
      "Lorem ipsum dolor sit consectetuer adipiscing sed diam nonummy nibhini",
  },
  {
    productImg: sofa1Logo,
    productPrice: 2.2,
    productOfferPrice: 1.129,
    inStockText: "200 stuks",
    productTag: "+/- Werkdagen levertijd",
    offerImage: offerLogo,
    productText:
      "Lorem ipsum dolor sit consectetuer adipiscing sed diam nonummy nibhini",
  },
  {
    productImg: sofa1Logo,
    productPrice: 2.2,
    productOfferPrice: 1.129,
    inStockText: "200 stuks",
    productTag: "+/- Werkdagen levertijd",
    offerImage: offerLogo,
    productText:
      "Lorem ipsum dolor sit consectetuer adipiscing sed diam nonummy nibhini",
  },
  {
    productImg: sofa1Logo,
    productPrice: 2.2,
    productOfferPrice: 1.129,
    inStockText: "200 stuks",
    productTag: "+/- Werkdagen levertijd",
    offerImage: offerLogo,
    productText:
      "Lorem ipsum dolor sit consectetuer adipiscing sed diam nonummy nibhini",
  },
  {
    productImg: sofa1Logo,
    productPrice: 2.2,
    productOfferPrice: 1.129,
    inStockText: "200 stuks",
    productTag: "+/- Werkdagen levertijd",
    offerImage: offerLogo,
    productText:
      "Lorem ipsum dolor sit consectetuer adipiscing sed diam nonummy nibhini",
  },
];

export const CAROUSEL_SETTING_FIRST = {
  centerMode: true,
  centerPadding: "60px",
  dots: false,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  slidesToShow: 2,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1499,
      settings: {
        centerPadding: "40px",
      },
    },
    {
      breakpoint: 1365,
      settings: {
        centerPadding: "30px",
      },
    },
    {
      breakpoint: 1199,
      settings: {
        centerPadding: "30px",
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 992,
      settings: {
        centerPadding: "40px",
      },
    },
    {
      breakpoint: 767,
      settings: {
        centerPadding: "30px",
      },
    },
    {
      breakpoint: 575,
      settings: {
        centerPadding: "20px",
      },
    },
  ],
};

export const CAROUSEL_SETTING_SECOND = {
  centerMode: true,
  centerPadding: "60px",
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  initialSlide: 0,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 4,
        centerPadding: "50px",
      },
    },
    {
      breakpoint: 1441,
      settings: {
        slidesToShow: 4,
        centerPadding: "40px",
      },
    },
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 3,
        centerPadding: "45px",
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        centerPadding: "40px",
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        centerPadding: "30px",
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 2,
        centerPadding: "20px",
      },
    },
  ],
};

export const CAROUSEL_SETTING_FOURTH = {
  centerMode: false,
  centerPadding: "60px",
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 4,
        centerPadding: "40px",
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        initialSlide: 2,
        centerPadding: "30px",
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 2,
        initialSlide: 2,
        centerPadding: "25px",
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};
export const CAROUSEL_SETTING_FIFTH = {
  centerMode: false,
  centerPadding: "40px",
  dots: false,
  infinite: true,
  arrows: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  adaptiveHeight: false,
  responsive: [
    {
      breakpoint: 1439,
      settings: {
        slidesToShow: 3,
        centerPadding: "30px",
      },
    },
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 3,
        centerPadding: "25px",
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 4,
        centerPadding: "30px",
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        centerPadding: "30px",
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 2,
        centerPadding: "15px",
      },
    },
    {
      breakpoint: 350,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default SELECTED_PRODUCT;
